<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Add User</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="User's Name"
              v-model="newUser.name"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Email"
              v-model="newUser.email"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="password"
              placeholder="Password"
              v-model="newUser.password"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="password"
              placeholder="Confirm Password"
              v-model="newUser.confirm_password"
            />
          </div>

          <div class="inline-block mt-5 w-full pr-1">
            <h2 class="flex items-center">
              <span class="font-bold">Permissions</span>
              <span class="inline-block ml-auto text-sm font-italic"
                >Selected :
                <div class="bg-primary p-1 rounded inline-block"></div
              ></span>
            </h2>
            <!-- Inquiries -->
            <p>Inquiries</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white': newUser.permissions.includes(
                    p.id
                  ),
                  'bg-gray-100': !newUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.inquiryPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Inquiries -->
            <!-- Customers -->
            <p>Customers</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white': newUser.permissions.includes(
                    p.id
                  ),
                  'bg-gray-100': !newUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.customerPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Customers -->
            <!-- Vendors -->
            <p>Vendors</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white': newUser.permissions.includes(
                    p.id
                  ),
                  'bg-gray-100': !newUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.vendorPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Vendors -->
            <!-- Categories -->
            <p>Categories</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white': newUser.permissions.includes(
                    p.id
                  ),
                  'bg-gray-100': !newUser.permissions.includes(p.id),
                }"
                class="text-sm mr-1 rounded inline-block px-2 py-1"
                v-for="(p, i) in permissions.categoryPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Categories -->
            <!-- Users -->
            <p>Users</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white': newUser.permissions.includes(
                    p.id
                  ),
                  'bg-gray-100': !newUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.userPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Users -->
          </div>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
              @click.prevent="onUserAdded"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapActions } from "vuex";
import permissions from "@/utils/permissions";

export default {
  name: "newUserModal",
  props: ["isVisible"],
  emits: ["overlayClicked"],
  data() {
    return {
      permissions,
      newUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        permissions: [],
      },
    };
  },
  mounted() {
    this.setDefaultPermissions();
  },
  computed: {
    canSave() {
      return (
        this.newUser.name &&
        this.newUser.password &&
        this.newUser.confirm_password &&
        this.newUser.password === this.newUser.confirm_password &&
        this.newUser.email
      );
    },
  },
  methods: {
    ...mapActions(["addUser"]),
    onUserAdded() {
      this.addUser(this.newUser).then(() => {
        this.$emit("overlayClicked");
        this.resetForm();
      });
    },
    resetForm() {
      this.newUser = {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        permissions: [],
      };
      this.setDefaultPermissions();
    },
    setDefaultPermissions() {
      this.newUser.permissions = [
        ...this.newUser.permissions,
        ...permissions.inquiryPermissions.map((p) => p.id),
      ];
      this.newUser.permissions = [
        ...this.newUser.permissions,
        ...permissions.customerPermissions.map((p) => p.id),
      ];
      this.newUser.permissions = [
        ...this.newUser.permissions,
        ...permissions.vendorPermissions.map((p) => p.id),
      ];
      this.newUser.permissions = [
        ...this.newUser.permissions,
        ...permissions.categoryPermissions.map((p) => p.id),
      ];
    },
    selectOrUnselectPermission(permission) {
      if (this.newUser.permissions.includes(permission.id)) {
        this.newUser.permissions = this.newUser.permissions.filter(
          (p) => p !== permission.id
        );
      } else {
        this.newUser.permissions.push(permission.id);
      }
    },
    // resetForm() {
    //   this.newStatus = {
    //     title: "",
    //     date: "",
    //   };
    //   this.$emit("overlayClicked");
    // },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>