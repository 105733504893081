const allPermissions = [
  // Inquiries
  "READ_INQUIRIES",
  "CREATE_INQUIRIES",
  "UPDATE_INQUIRIES",
  "DELETE_INQUIRIES",
  "LOCK_INQUIRIES",
  "UNLOCK_INQUIRIES",

  // Customers
  "READ_CUSTOMERS",
  "CREATE_CUSTOMERS",
  "UPDATE_CUSTOMERS",
  "DELETE_CUSTOMERS",

  // Vendors
  "READ_VENDORS",
  "CREATE_VENDORS",
  "UPDATE_VENDORS",
  "DELETE_VENDORS",

  // Categories
  "READ_CATEGORIES",
  "CREATE_CATEGORIES",
  "UPDATE_CATEGORIES",
  "DELETE_CATEGORIES",

  // Users
  "READ_USERS",
  "CREATE_USERS",
  "UPDATE_USERS",
  "DELETE_USERS",
];

const inquiryPermissions = [
  {
    id: "READ_INQUIRIES",
    name: "Read",
  },
  {
    id: "CREATE_INQUIRIES",
    name: "Create",
  },
  {
    id: "UPDATE_INQUIRIES",
    name: "Update",
  },
  {
    id: "DELETE_INQUIRIES",
    name: "Delete",
  },
  {
    id: "LOCK_INQUIRIES",
    name: "Lock",
  },
  {
    id: "UNLOCK_INQUIRIES",
    name: "Unlock",
  },
];

const customerPermissions = [
  {
    id: "READ_CUSTOMERS",
    name: "Read",
  },
  {
    id: "CREATE_CUSTOMERS",
    name: "Create",
  },
  {
    id: "UPDATE_CUSTOMERS",
    name: "Update",
  },
  {
    id: "DELETE_CUSTOMERS",
    name: "Delete",
  },
];

const vendorPermissions = [
  {
    id: "READ_VENDORS",
    name: "Read",
  },
  {
    id: "CREATE_VENDORS",
    name: "Create",
  },
  {
    id: "UPDATE_VENDORS",
    name: "Update",
  },
  {
    id: "DELETE_VENDORS",
    name: "Delete",
  },
];

const categoryPermissions = [
  {
    id: "READ_CATEGORIES",
    name: "Read",
  },
  {
    id: "CREATE_CATEGORIES",
    name: "Create",
  },
  {
    id: "UPDATE_CATEGORIES",
    name: "Update",
  },
  {
    id: "DELETE_CATEGORIES",
    name: "Delete",
  },
];

const userPermissions = [
  {
    id: "READ_USERS",
    name: "Read",
  },
  {
    id: "CREATE_USERS",
    name: "Create",
  },
  {
    id: "UPDATE_USERS",
    name: "Update",
  },
  {
    id: "DELETE_USERS",
    name: "Delete",
  },
];

const permissions = {
  allPermissions,
  inquiryPermissions,
  customerPermissions,
  vendorPermissions,
  categoryPermissions,
  userPermissions,
};

export default permissions;
