<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Edit User: {{ user.name }}</h1>

      <p
        class="my-4 text-red-500 text-sm"
        v-if="
          user.name === 'Admin' ||
          user.name === 'SuperAdmin' ||
          user.name === 'Super Admin'
        "
      >
        This user cannot be edited.
      </p>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              :disabled="
                user.name === 'Admin' ||
                user.name === 'SuperAdmin' ||
                user.name === 'Super Admin'
              "
              placeholder="User's Name"
              v-model="currentUser.name"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              :disabled="
                user.name === 'Admin' ||
                user.name === 'SuperAdmin' ||
                user.name === 'Super Admin'
              "
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Email"
              v-model="currentUser.email"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="password"
              placeholder="Password"
              v-model="currentUser.password"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="password"
              placeholder="Confirm Password"
              v-model="currentUser.confirm_password"
            />
          </div>

          <div
            v-if="
              user.name !== 'Admin' &&
              user.name !== 'SuperAdmin' &&
              user.name !== 'Super Admin'
            "
            class="inline-block mt-5 w-full pr-1"
          >
            <h2 class="flex items-center">
              <span class="font-bold">Permissions</span>
              <span class="inline-block ml-auto text-sm font-italic"
                >Selected :
                <div class="bg-primary p-1 rounded inline-block"></div
              ></span>
            </h2>
            <!-- Inquiries -->
            <p>Inquiries</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white':
                    currentUser.permissions.includes(p.id),
                  'bg-gray-100': !currentUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.inquiryPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Inquiries -->
            <!-- Customers -->
            <p>Customers</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white':
                    currentUser.permissions.includes(p.id),
                  'bg-gray-100': !currentUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.customerPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Customers -->
            <!-- Vendors -->
            <p>Vendors</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white':
                    currentUser.permissions.includes(p.id),
                  'bg-gray-100': !currentUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.vendorPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Vendors -->
            <!-- Categories -->
            <p>Categories</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white':
                    currentUser.permissions.includes(p.id),
                  'bg-gray-100': !currentUser.permissions.includes(p.id),
                }"
                class="text-sm mr-1 rounded inline-block px-2 py-1"
                v-for="(p, i) in permissions.categoryPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Categories -->
            <!-- Users -->
            <p>Users</p>
            <div class="flex my-2">
              <button
                @click="selectOrUnselectPermission(p)"
                :class="{
                  'bg-primary shadow text-white':
                    currentUser.permissions.includes(p.id),
                  'bg-gray-100': !currentUser.permissions.includes(p.id),
                }"
                class="
                  text-sm
                  cursor-pointer
                  mr-1
                  rounded
                  inline-block
                  px-2
                  py-1
                "
                v-for="(p, i) in permissions.userPermissions"
                :key="i"
              >
                {{ p.name }}
              </button>
            </div>
            <!-- /Users -->
          </div>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
              @click.prevent="onUserSave"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapActions } from "vuex";
import permissions from "@/utils/permissions";

export default {
  name: "EditUserModal",
  props: ["isVisible", "user"],
  emits: ["overlayClicked"],
  data() {
    return {
      permissions,
      currentUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        permissions: [],
      },
    };
  },
  mounted() {
    this.currentUser = JSON.parse(JSON.stringify(this.$props.user));
    this.currentUser.password = "";
    this.currentUser.confirm_password = "";
  },
  computed: {
    canSave() {
      return (
        this.currentUser.name &&
        this.currentUser.password === this.currentUser.confirm_password &&
        this.currentUser.email
      );
    },
  },
  methods: {
    ...mapActions(["editUser"]),
    onUserSave() {
      this.editUser(this.currentUser).then(() => {
        this.$emit("overlayClicked");
      });
    },
    selectOrUnselectPermission(permission) {
      if (this.currentUser.permissions.includes(permission.id)) {
        this.currentUser.permissions = this.currentUser.permissions.filter(
          (p) => p !== permission.id
        );
      } else {
        this.currentUser.permissions.push(permission.id);
      }
    },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>