<template>
  <div class="w-full mx-auto text-gray-900">
    <div style="margin-top: 5rem" class="mb-2 px-4 flex">
      <button
        @click="newUserModalVisible = true"
        class="bg-primary text-white px-4 py-2 rounded"
      >
        Add
      </button>
    </div>
    <div class="px-3 py-4 flex justify-center">
      <table class="w-full text-md bg-white border rounded mb-4">
        <tbody>
          <tr class="border-b">
            <th class="text-left py-1 px-4">Name</th>
            <th class="text-left py-1 px-4">Email</th>
            <th></th>
          </tr>
          <UserTableItem
            v-for="user in users"
            :key="user._id"
            :user="user"
          ></UserTableItem>
        </tbody>
      </table>
    </div>
  </div>
  <NewUserModal
    :isVisible="newUserModalVisible"
    @overlayClicked="newUserModalVisible = false"
  ></NewUserModal>
</template>

<script>
import NewUserModal from "@/components/modules/users/NewUserModal";
import UserTableItem from "@/components/modules/users/UserTableItem";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Users",
  data() {
    return {
      newUserModalVisible: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    ...mapActions(["getUsers"]),
  },
  computed: {
    ...mapGetters(["users"]),
  },
  components: {
    NewUserModal,
    UserTableItem,
  },
};
</script>

<style>
</style>