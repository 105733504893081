<template>
  <tr class="border-b hover:bg-gray-100">
    <td class="px-4">{{ user.name }}</td>
    <td class="px-4">{{ user.email }}</td>
    <td class="px-4 flex justify-end">
      <button @click="editModalVisible = true">
        <span
          class="
            text-grey-lighter
            font-bold
            py-0
            px-3
            rounded
            text-xs
            bg-green
            hover:bg-green-dark
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            class="mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
            ></path>
            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
          </svg>
        </span>
      </button>
      <button
        v-if="
          user.name !== 'Admin' &&
          user.name !== 'SuperAdmin' &&
          user.name !== 'Super Admin'
        "
        @click="deleteConfirmVisible = true"
      >
        <span
          class="
            text-grey-lighter
            font-bold
            py-0
            px-3
            rounded
            text-xs
            bg-blue
            hover:bg-blue-dark
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path
              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
            ></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </span>
      </button>
    </td>
    <EditUserModal
      :isVisible="editModalVisible"
      @overlayClicked="editModalVisible = false"
      :user="user"
    ></EditUserModal>
  </tr>

  <!-- DELETE CONFIRM MODAL -->
  <div
    v-if="deleteConfirmVisible"
    @click="deleteConfirmVisible = false"
    class="confirm-overlay"
  ></div>
  <div
    v-if="deleteConfirmVisible"
    class="delete-confirm text-xl shadow rounded p-10"
  >
    <div>
      Delete
      <span class="font-bold">{{ user.name }}</span> from users?
      <br />
      <span class="inline-block my-4 text-red-700"
        >This action cannot be undone.</span
      >
    </div>
    <div class="flex justify-end mt-6">
      <button
        @click="deleteConfirmVisible = false"
        class="py-2 px-4 bg-green-600 text-white rounded mr-2"
      >
        No
      </button>
      <button
        @click="(deleteConfirmVisible = false), deleteUser(user)"
        class="py-2 px-4 bg-red-600 text-white rounded"
      >
        Yes
      </button>
    </div>
  </div>
  <!-- / DELETE CONFIRM MODAL -->
</template>

<script>
import EditUserModal from "@/components/modules/users/EditUserModal";
import { mapActions } from "vuex";
export default {
  name: "UserTableItem",
  props: ["user"],
  data() {
    return {
      deleteConfirmVisible: false,
      editModalVisible: false,
    };
  },
  methods: {
    ...mapActions(["deleteUser"]),
  },
  components: {
    EditUserModal,
  },
};
</script>

<style scoped>
.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
</style>